<template>
	<div>
		<main-nav :activeIndex="activeIndex"></main-nav>
		<div class="solution-header-container">
			<div class="solution-header-text">
				<h1 data-caption-delay="0" class="wow fadeInLeft">智能电控</h1>
				<p data-caption-delay="100" class="wow fadeInRight">适用于新能源动力域以及智能底盘域的各类在环仿真测试验证方案</p>
				<div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
					<div>立即咨询</div>
				</div>
			</div>
		</div>
		<div class="sw-tab-title" ref="tab" :class="tabFixed ? getTabFixedClass() : ''">
			<div class="container">
				<el-tabs v-model="activeProduct" @tab-click="handleTabClick">
					<el-tab-pane label="新能源三电测试" name="1">新能源三电测试</el-tab-pane>
					<el-tab-pane label="智能底盘测试" name="2">智能底盘测试</el-tab-pane>
					<el-tab-pane label="车身电子测试" name="3">车身电子测试</el-tab-pane>
					<el-tab-pane label="智能车灯测试" name="4">智能车灯测试</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<div :class="tabFixed?'solution-tab-fixed':''">
			<div v-if="activeProduct === '1'">
				<section class="solution-intros wow fadeInLeft">
					<div class="container">
						<div class="normal-title">解决方案介绍</div>
						<div class="all-intro">
							<el-row>
								<el-col :span="24">
									<div class="intro-text" style="width: 100%;">
										<p>新能源三电系统HIL测试平台可以实现动力域控制器在虚拟环境中的仿真测试，包括整车控制器（VCU）、电机控制器（MCU）及电池管理系统（BMS）的仿真测试。验证三电系统的控制策略，提高三电系统的可靠性及安全性。
										</p>
									</div>
									<div class="intro-text" style="width: 100%;">
										<p>新能源三电系统HIL测试平台主要实现新能源汽车整车模型的实时运行，模拟三电系统各被测控制器的输入信号，采集各控制器的输出和必要的输入信号，实现各控制器对实时模型的闭环控制，最终完成各控制器的功能测试和验证。
										</p>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				<section class="vehicle-solution-sections wow fadeInRight">
					<div class="container">
						<div class="normal-title">功能特点</div>

						<div class="all-function">
							<ul class="priority-list">
								<li>可以实现VCU、MCU、BMS三个控制器分别进行单独的HIL功能测试，也可以通过三个HIL测试平台级联实现三个控制器的HIL联合测试</li>
								<li>试验管理软件具有图形化的操作界面，方便手动化测试</li>
								<li>自动化测试软件具有多样的测试用例编辑器，测试序列的灵活参数化</li>
								<li>车辆动力学软件具有传动系统、电气系统、发动机系统以及整车控制单元等模块，可以搭建各种形式的动力系统模型</li>
							</ul>
						</div>
					</div>
				</section>
				<section
					class="electric-solution-components network-solution-components chassisHIL-components wow fadeInLeft">
					<div class="container" style="height: 530px">
						<div class="normal-title">核心组件</div>
						<div class="solution-components">
							<el-row class="components-content" :gutter="40">
								<el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
									<img src="../../../assets/images/solution-hil-components.png" alt="">
								</el-col>
								<el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
									<div class="components-text">
										<div>
											<h1>实时平台</h1>
											<p>Vector VT System、NI PXI等</p>
										</div>
										<div :style="isMobile ? 'margin-top: 20px' :'margin-top: 51px'">
											<h1>试验管理软件</h1>
											<p>CANoe、Veristand等同类软件</p>
										</div>
										<div :style="isMobile ? 'margin-top: 20px' :'margin-top: 51px'">
											<h1>自动化测试软件</h1>
											<p>vTESTstudio、Teststand等同类软件</p>
										</div>
										<div :style="isMobile ? 'margin-top: 20px' :'margin-top: 59px'">
											<h1>车辆动力学软件</h1>
											<p>DYNA4或其他第三方同类软件</p>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				<section class="dyna-product-system-picture wow fadeInRight" style="background: #E2EAF4">
					<div class="container">
						<div class="normal-title" style="width: 280px;">系统图片</div>
						<div class="all-system-picture">
							<div class="system-picture white-bg-pic-deal">
								<img src="../../../assets/images/solution-tripleElectricHIL-architecture-pic.png"
									alt="">
							</div>
						</div>
					</div>
				</section>

			</div>
			<div v-if="activeProduct === '2'">
				<section class="solution-intros wow fadeInLeft">
					<div class="container">
						<el-row :gutter="20">
							<el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
								<div class="normal-title">解决方案介绍</div>
								<div class="all-intro">
									<div class="intro-text" style="width: 95%;">
										<p>
											汽车底盘是由传动系、行驶系、转向系及制动系组成的综合系统，智能底盘与ADAS&AD的发展相辅相成。随着底盘域电控化、智能化程度越来越高，底盘域HIL的作用和优势也越来越明显。
											底盘域HIL
											测试系统能够针对底盘相关控制器、传感器、执行器进行半实物仿真测试，目标是搭建底盘域控制器的闭环测试环境。基于成熟的实时平台、功能完备的车辆动力学模型搭建起目标车辆的虚拟仿真系统，
											通过供电、I/O资源及总线资源将被测对象集成到虚拟仿真系统中，可以对具体工况下的功能、通讯、诊断、Failsafe等进行测试和验证。
										</p>
									</div>
								</div>
							</el-col>
							<el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
								<div class="intro-picture">
									<img src="../../../assets/images/solution-chassisHIL-intro.png" alt=""
										style="display: block;width: 100%;margin-top: 15px;">
								</div>
							</el-col>
						</el-row>
					</div>
				</section>
				<section class="vehicle-solution-sections wow fadeInRight">
					<div class="container">
						<div class="normal-title">功能特点</div>
						<div class="function-intro">
							<p>基于成熟的平台方案和丰富的技术储备为客户量身定制适合的方案，特点如下：
							</p>
						</div>
						<div class="all-function">
							<ul class="priority-list">
								<li>成熟稳定的测试平台，多核并行，计算能力强</li>
								<li>I/O接口硬件丰富，平台拓展性好</li>
								<li>支持众多主流平台软件，兼容性好</li>
								<li>图形化的操作界面，人机交互好</li>
								<li>可同时进行试验管理及网络节点监控，综合性好</li>
								<li>测试用例编辑简单，自动生成测试报告，方便性好</li>
								<li>提供了ECU级、模块级、系统级方案，满足不同客户的需求</li>
								<li>丰富的模型开发能力，使虚拟车辆和被测对象实现了完美的融合</li>
								<li>执行台架可实现手动输入和自动化输入，针对不同测试工况灵活匹配</li>
								<li>可与ADAS相关功能相互配合，实现整车在环（VIL）的测试和验证</li>
							</ul>
						</div>
					</div>
				</section>
				<section
					class="electric-solution-components network-solution-components chassisHIL-components wow fadeInLeft">
					<div class="container" style="height: 530px">
						<div class="normal-title">核心组件</div>
						<div class="solution-components">
							<el-row class="components-content" :gutter="40">
								<el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
									<img src="../../../assets/images/solution-hil-components.png" alt="">
								</el-col>
								<el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
									<div class="components-text">
										<div>
											<h1>实时平台</h1>
											<p>Vector VT System、NI PXI等</p>
										</div>
										<div :style="isMobile ? 'margin-top: 20px' :'margin-top: 59px'">
											<h1>车辆动力学软件</h1>
											<p>DYNA4或其他第三方同类软件</p>
										</div>
										<div :style="isMobile ? 'margin-top: 20px' :'margin-top: 51px'">
											<h1>试验管理软件</h1>
											<p>CANoe或其他第三方同类软件</p>
										</div>
										<div :style="isMobile ? 'margin-top: 20px' :'margin-top: 51px'">
											<h1>自动化测试软件</h1>
											<p>vTESTstudio或其他第三方同类软件</p>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</section>
				<section class="solution-application wow fadeInRight" style="height: 610px">
					<div class="container">
						<el-row :gutter="20">
							<el-col :span="24">
								<div class="normal-title">应用领域</div>
								<div class="all-application" style="margin-top: 40px">
									<p>适用于乘用车、商用车底盘涉及到的所有电控系统及零部件，包括但不限于下图所示：</p>
									<div class="application-picture">
										<img src="../../../assets/images/solution-chassisHIL-application-pic.png"
											alt="">
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</section>
				<section class="dyna-product-system-picture wow fadeInLeft">
					<div class="container">
						<div class="normal-title">系统图片</div>
						<div class="all-system-picture">
							<div class="system-picture">
								<img src="../../../assets/images/solution-chassisHIL-system-pic.png" alt="">
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
		<div v-if="activeProduct === '3'">
			<section class="solution-intros wow fadeInLeft">
				<div class="container">
					<div class="normal-title">解决方案介绍</div>
					<div class="all-intro">
						<p class="intro-detail">
							车身域HIL测试平台是车身域控制器功能验证的主要手段，既高效又便利，不仅缩短了车身域控制器的开发周期，还可以为新车型顺利量产起到保驾护航的作用。为满足不同客户的实际需求，可以提供HIL测试平台集成、调试、自动化测试服务等多项工程服务。
						</p>
					</div>
				</div>
			</section>
			<section class="vehicle-solution-sections solution-functions wow fadeInRight">
				<div class="container">
					<div class="normal-title">功能特点</div>
					<div class="all-function" @mouseenter="$refs.mySwiper.swiper.autoplay.stop()"
						@mouseleave="$refs.mySwiper.swiper.autoplay.start()">
						<swiper :options="swiperOption" ref="mySwiper">
							<swiper-slide v-for="(item, index) in domainFunctionList" :key="index">
								<div class="card-item">
									<el-card class="solution-function-card" shadow="hover">
										<div class="card-icon">
											<i :class=" item.icon "></i>
										</div>
										<div class="card-text">
											<p>{{ item.content }}</p>
										</div>
									</el-card>
								</div>
							</swiper-slide>
							<div class="swiper-button-prev" slot="button-prev"></div>
							<div class="swiper-button-next" slot="button-next"></div>
						</swiper>
					</div>
				</div>
			</section>
			<section class="domainHIL-solution-components wow fadeInLeft">
				<div class="container" style="height: 500px;">
					<div class="normal-title">核心组件</div>
					<div class="solution-components">
						<div class="components-content">
							<el-row class="components-content" :gutter="40">
								<el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
									<img src="../../../assets/images/solution-electric-components-pic.png" alt="">
								</el-col>
								<el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
									<div class="components-text">
										<div>
											<h1>实时平台</h1>
											<ol>
												<li>Vector VT System、NI PXI等</li>
											</ol>
										</div>
										<div :style="isMobile ? 'margin-top: 20px' :'margin-top: 160px'">
											<h1>传感器仿真设备</h1>
											<ol>
												<li>CANoe、Veristand等同类软件</li>
											</ol>
										</div>
										<div :style="isMobile ? 'margin-top: 20px' :'margin-top: 100px'">
											<h1>电源系统</h1>
											<ol>
												<li>vTESTstudio、Teststand等同类软件</li>
											</ol>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</div>
			</section>
			<section class="solution-application wow fadeInRight" style="height: 260px">
				<div class="container">
					<el-row :gutter="20">
						<el-col :span="24">
							<div class="normal-title">应用领域</div>
							<div class="all-application" style="margin-top: 40px">
								<p>车身域控制器的仿真测试</p>
							</div>
						</el-col>
					</el-row>
				</div>
			</section>
			<section class="dyna-product-system-picture wow fadeInLeft">
				<div class="container">
					<div class="normal-title">系统图片</div>
					<div class="all-system-picture">
						<div class="system-picture white-bg-pic-deal">
							<img src="../../../assets/images/solution-domainHIL-system-pic.png" alt="">
						</div>
					</div>
				</div>
			</section>
		</div>
		<div v-if="activeProduct === '4'">
			<section class="solution-intros electric-solution-intro wow fadeInLeft">
				<div class="container">
					<div class="normal-title">解决方案介绍</div>
					<div class="all-intro">
						<el-row>
							<el-col :span="24">
								<p class="intro-detail">
									随着智能驾驶的热潮，为了提升汽车的智能化和安全性，车灯也出现了与智能驾驶相结合的产物ADB（智能远光灯）与DL（数字投影大灯）。
									基于智能驾驶仿真验证的方案同样可以延伸至智能车灯的仿真验证，因此为了解决结合了智能驾驶功能的智能车灯系统的仿真验证问题，东信创智给出了IDH（智能数字大灯）XIL解决方案。
								</p>
							</el-col>
						</el-row>
					</div>
				</div>
			</section>
			<section class="vehicle-solution-sections wow fadeInRight">
				<div class="container">
					<div class="normal-title">功能特点</div>

					<div class="all-function">
						<ul class="priority-list">
							<li>IDH XIL 平台基于ADAS HIL平台拓展而来，可以满足对智能车灯算法从MIL到VIL的全流程仿真测试</li>
							<li>基于ADAS系统的场景库可以极大程度的加速产品的开发验证</li>
						</ul>
					</div>
				</div>
			</section>
			<section
				class="electric-solution-components network-solution-components lightXIL-components wow fadeInLeft">
				<div class="container" style="height: 530px">
					<div class="normal-title">核心组件</div>
					<div class="solution-components">
						<el-row class="components-content" :gutter="40">
							<el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
								<img src="../../../assets/images/solution-network-components.png" alt="">
							</el-col>
							<el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
								<div class="components-text">
									<div>
										<h1>仿真软件</h1>
										<ol>
											<li>场景仿真软件VTD</li>
											<li>动力学仿真软件DYNA4</li>
											<li>试验管理软件CANoe</li>
											<li>自动化测试软件vTESTStudio等</li>
										</ol>
									</div>
									<div :style="isMobile ? 'margin-top: 20px' :'margin-top: 80px'">
										<h1>硬件</h1>
										<ol>
											<li>高性能图形工作站</li>
											<li>高性能工作站</li>
											<li>HIL测试台架</li>
											<li>视频采集卡等</li>
										</ol>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
			</section>
			<section class="solution-application wow fadeInRight">
				<div class="container">
					<div class="normal-title">应用领域</div>
					<div class="all-application">
						<div class="all-application">
							<div class="application-content">
								<div class="application-icon">
									<img src="../../../assets/images/application-picture.svg" alt="">
								</div>
								<div class="application-text">
									<p>IDH 仿真测试</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="dyna-product-system-picture">
				<div class="container">
					<div class="normal-title">系统图片</div>
					<div class="all-system-picture">
						<div class="system-picture white-bg-pic-deal">
							<img src="../../../assets/images/solution-lightXML-system-pic.png" alt="">
						</div>
					</div>
				</div>
			</section>
		</div>
		<bzInfo style="margin-top: 0" />
		<main-footer></main-footer>
	</div>
</template>

<script>
	import mainNav from '@/components/MainNav'
	import mainFooter from '@/components/MainFooter'
	import bzInfo from '@/components/BzInfo'

	export default {
		name: "ElectronicControl",
		components: {
			mainNav,
			mainFooter,
			bzInfo
		},
		data() {
			return {
				activeIndex: '2-4',
				tabFixed: false,
				activeProduct: '1',

				componentsTableData: [{
						project: "VCU HIL测试",
						description: "主要包含系统上下电测试，能量管理功能测试，扭矩分配功能测试，自定义驾驶循环工况测试，手动驾驶模拟功能测试等。",
					},
					{
						project: "MCU HIL测试",
						description: "主要包含相电流传感器信号、电机温度传感器信号及位置传感器信号采样测试，电机逆变器驱动功能测试，扭矩转速模式切换测试，扭矩响应及跟踪情况测试等。",
					},
					{
						project: "BMS HIL测试",
						description: "主要包含单体电池、电池包及高压回路上的电压、电流、温度采样测试，充电管理测试，高压互锁测试，绝缘电阻监测测试，SOC及SOH评估测试，均衡管理测试，热管理测试等。",
					},
				],
				swiperOption: {
					spaceBetween: 20, // 图片之间的间距
					centeredSlides: false, // 居中还是从图1开始
					slidesPerView: 2, // 一屏幕显示几个? 数字或者默认  auto 自动。
					breakpointsInverse: true,
					breakpoints: {
						//当宽度大于等于1100
						1100: {
							slidesPerView: 4,
							spaceBetween: 10
						}
					},
					notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
					// loop: true, // 循环吗
					initialSlide: 0, // 从第几个开始
					autoplay: {
						delay: 5000, // 等5秒下一个
						disableOnInteraction: false // 中间滑动一下，取消自动吗？
					},
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					}, // 下按钮
					speed: 800, // 滑动时候动画的速度
					paginationClickable: true, // 下面按钮让点吗
					navigation: {
						prevEl: '.swiper-button-prev', // 左侧按钮
						nextEl: '.swiper-button-next' // 右侧按钮
					},
					// effect: 'fade', // 渐入效果
					watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
					watchSlidesVisibility: true // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
					// autoHeight: true  // 图片高度自适应
				},
				domainFunctionList: [{
						icon: "ri-roadster-fill",
						content: "图形化用例开发编辑环境，含有丰富的可拖拽函数控件"
					},
					{
						icon: "ri-roadster-fill",
						content: "可以依据测试条件自动遍历生成测试脚本，保证测试覆盖率可达100%"
					},
					{
						icon: "ri-roadster-fill",
						content: "车身域所有子系统功能及诊断功能的HIL验证（如：内外灯、门锁、雨刮、车窗、座椅等）"
					},
					{
						icon: "ri-roadster-fill",
						content: "可以实现车身域控制器单独的HIL功能测试，也可以结合车身电子电气台架实现HIL联合测试"
					},

				],
				chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
				isMobile: false,
			}
		},
		mounted() {
			new this.$wow.WOW().init();
			document.title = "智能电控 - 解决方案 - 东信创智";
			window.addEventListener("scroll", this.handleScroll);
			this.isMobile = document.documentElement.clientWidth < 1200
			window.onresize = () => {
				return (() => {
					this.isMobile = document.documentElement.clientWidth < 1200;
				})();
			}
		},
		destroyed() {
			window.removeEventListener("scroll", this.handleScroll);
		},
		methods: {
			linkUrl(url) {
				window.open(url, '_blank') // 在新窗口打开外链接
				// window.location.href =this.indexro;  //在本页面打开外部链接
			},
			handleScroll() {
				if (document.documentElement.clientWidth > 1100) {
					let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 70
					if (top < 0 && top > -630) {
						this.tabFixed = !this.tabFixed;
					} else if (document.documentElement.scrollTop > 630) {
						this.tabFixed = true;
					}
				} else {
					let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 50
					if (top < 0 && top > -250) {
						this.tabFixed = !this.tabFixed;
					} else if (document.documentElement.scrollTop > 250) {
						this.tabFixed = true;
					}
				}
			},
			getTabFixedClass() {
				if (document.documentElement.clientWidth < 1100) {
					return 'sw-tab-title-mobile-fixed'
				} else {
					return 'sw-tab-title-fixed'
				}
			},
			handleTabClick() {
				if (document.documentElement.clientWidth > 1100 && document.documentElement.scrollTop > 630) {
					document.documentElement.scrollTop = 630
				} else if (document.documentElement.clientWidth < 1100 && document.documentElement.scrollTop > 250) {
					document.documentElement.scrollTop = 250
				}
			}
		}
	}
</script>

<style scoped>

</style>
